import React, { Component } from "react";
import "../styles/Home.scss";
import { Link } from "react-router-dom";
import Navbar from "../components/NavBar.jsx";
import Footer from "../components/Footer.jsx";
import Primera from "../assets/images/ico-1EnfoquePyme.png";
import Segunda from "../assets/images/ico-2Enfoque2080.png";
import Tercera from "../assets/images/ico-3SolucionesI.png";
import Cuarto from "../assets/images/ico-4AsesoriaEn.png";
import Quinto from "../assets/images/ico-5FinanzasComo.png";
import Sexto from "../assets/images/ico-6Accesibilidad.png";
import { Jump } from "react-jump";

export default class Home extends Component {


  sendEmail = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("name", document.getElementById("name").value);
    formData.append("tel", document.getElementById("tel").value);
    formData.append("email", document.getElementById("email").value);
    formData.append("message", document.getElementById("message").value);

    fetch("/mail.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.text())
      .then((response) => {
        if (response === "\n1") {
          alert("Correo Enviado");
          document.getElementById("name").value = "";
          document.getElementById("tel").value = "";
          document.getElementById("email").value = "";
          document.getElementById("message").value = "";
        } else {
          console.log("Hubo un problema, contactanos por a contacto@bfinancial.com.mx");
        }
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // let counter = 1;
    // setInterval(() => {
    //   switch (counter) {
    //     case 1:
    //       document.getElementById("innerText").innerHTML = '“La calidad en la atención y servicios que brinda Bfinancial, ha permitido generar alianzas y confianza para <b>EA10 Support México</b>, aportando valor agregado y potencializando nuestras operaciones a través de la experiencia y coaching que Bfianancial ofrece”';
    //       break;
    //     case 2:
    //       document.getElementById("innerText").innerHTML = '“Nos dieron una solución integral, el plan de negocios de <b>Teknometer</b> con un soporte financiero, fiscal y legal, y en los tiempos acordados.”';
    //       break;
    //     default:
    //       break;
    //   }

    //   counter++;
    //   if (counter === 3) counter = 1;
    // }, 4000);

    //   <section className="clientExperience">
    //   <div className="fixedContainer">
    //     <div className="experience">
    //       <article>
    //         {/* <img src={Disney} alt="Disney" /> */}
    //       </article>
    //       <article>
    //         <h2>Conoce la experiencia de nuestros clientes</h2>
    //         <p id="innerText">
    //           “La calidad en la atención y servicios que brinda Bfinancial, ha permitido generar alianzas y confianza para <b>EA10 Support México</b>, aportando valor agregado y potencializando nuestras operaciones a través de la experiencia y coaching que Bfianancial ofrece”
    //         </p>
    //       </article>
    //     </div>
    //   </div>
    // </section>
  };
  render() {
    return (
      <>
        <header>
          <Navbar />
          <div className="fixedContainer">

            <h1>
              <span>Expande&nbsp;</span>
              tu negocio, desarrolla tus <br />
              capacidades
              <span>&nbsp;financieras.</span>
            </h1>
            <div className="types">
              <ul>
                <li>
                  <Link to="/desarrollo"> Desarrollo de negocios </Link>
                </li>
                <li>
                  <Link to="/modelo"> Modelos financieros </Link>
                </li>
                <li>
                  <Link to="/servicios">CFO servicios financieros </Link>
                </li>
                <li>
                  <Link to="/capacitacion"> Capacitación </Link>
                </li>
              </ul>
            </div>
            <button className="primaryButton">
              <Jump target={".typeTiles"}>Conoce nuestros servicios</Jump>
            </button>
          </div>
        </header>
        <section className="whyPoints">
          <div className="fixedContainer">
            <div className="titleContainer">
              <h2>¿Porque BFinancial?</h2>
              <p>
                Para nosotros lo más importante es apoyar al crecimiento del
                Negocio con una <span>propuesta Integral</span> flexible,
                personalizada y accesible
              </p>
            </div>
            <div className="points">
              <article>
                <img src={Primera} alt="" />
                <h3>Enfoque PYME</h3>
                <p>Nuestra meta es el crecimiento de las PYMEs en México.</p>
              </article>
              <article>
                <img src={Segunda} alt="" />
                <h3>Enfoque 20/80</h3>
                <p>
                  Diseño de pequeñas iniciativas de alto impacto para el
                  crecimiento del Negocio.
                </p>
              </article>
              <article>
                <img src={Tercera} alt="" />
                <h3>Soluciones Integrales</h3>
                <p>
                  Nuestra propuesta de valor reside en una perspectiva global
                  del negocio.
                </p>
              </article>
              <article>
                <img src={Cuarto} alt="" />
                <h3>Asesoría en Línea</h3>
                <p>
                  Contamos con un equipo de asesores disponibles para atender a
                  nuestros clientes.
                </p>
              </article>
              <article>
                <img src={Quinto} alt="" />
                <h3>Finanzas como un Servicio</h3>
                <p>
                  Servicios financieros compartidos flexibles, acorde a las
                  necesidades y estructura del Negocio.
                </p>
              </article>
              <article>
                <img src={Sexto} alt="" />
                <h3>Accesibilidad</h3>
                <p>
                  Tú decides el nivel de servicio, número de personas, tiempo,
                  áreas y alcance, nosotros operamos.
                </p>
              </article>
            </div>
          </div>
        </section>
        <section className="typeTiles">
          <div className="imageLeft">
            <article className="firstTile"></article>
            <article>
              <h2>Desarrollo de negocios</h2>
              <p>
                Desde una perspectiva financiera y estratégica medimos el
                rendimiento actual del Negocio, se identifican áreas de mejora y
                se proponen iniciativas que potencialicen los recursos actuales
                del Negocio.
              </p>
              <Link to="/desarrollo">Conoce Más</Link>
            </article>
          </div>
          <div className="imageRight">
            <article>
              <h2>Modelo Financiero</h2>
              <p>
                Diseñamos herramientas de soporte a la operación del Negocio.
                Estamos comprometidos con generar valor a clientes a través de
                herramientas que aportan.
              </p>
              <Link to="/modelo">Conoce Más</Link>
            </article>
            <article className="secondTile"></article>
          </div>
          <div className="imageLeft">
            <article className="thirdTile"></article>
            <article>
              <h2>
                <span> CFO Servicios Financieros</span> Compartidos
              </h2>
              <p>
                Ponemos a un Director Financiero al alcance de tu Organización y
                un departamento integral que provea una visión financiera del
                Negocio, los asesore en la toma de decisiones y guíe a los
                departamentos administrativos con las mejores prácticas y
                sinergias para la óptima utilización de los recursos.
              </p>
              <Link to="/servicios">Conoce Más</Link>
            </article>
          </div>
          <div className="imageRight">
            <article>
              <h2>Capacitación</h2>
              <p>
                Desarrollar capacidades financieras y de negocios es nuestra
                meta, conoce los cursos que pueden ayudar a tu empresa.
              </p>
              <Link to="/capacitacion">Conoce Más</Link>
            </article>
            <article className="fourthTile"></article>
          </div>
        </section>

        <section id="contacto" className="contact">
          <div className="fixedContainer">
            <div className="formContainer">
              <div className="dataContact">
                <h2>
                  Ponte en contacto <br /> con nosotros.
                </h2>
                <article>
                  <p>Correo: </p>
                  <p>contacto@bfinancial.com.mx</p>
                </article>
                <article>
                  <p>Teléfono: </p>
                  <p>81 166 02 761</p>
                </article>
                <article>
                  <p>Horario: </p>
                  <p>
                    Lunes a Viernes <br />
                    9:00 AM - 1:00 PM
                    <br />
                    3:00 PM - 6:00 PM
                  </p>
                </article>
              </div>
              <div className="dataForm">
                <form onSubmit={(e) => this.sendEmail(e)}>
                  <article>
                    <label htmlFor="name">Nombre:</label>
                    <input type="text" name="name" id="name" />
                  </article>
                  <article>
                    <label htmlFor="tel">Teléfono:</label>
                    <input type="phone" name="tel" id="tel" />
                  </article>
                  <article>
                    <label htmlFor="email">Correo:</label>
                    <input type="email" name="email" id="email" />
                  </article>
                  <article>
                    <label htmlFor="message">Escribe tu mensaje aquí...</label>
                    <input type="text" name="message" id="message" />
                  </article>
                  <article>
                    <button type="submit" className="primaryButton">
                      Contáctanos
                    </button>
                  </article>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
