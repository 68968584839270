import React, { Component } from "react";
import "../styles/Develop.scss";
import Navbar from "../components/NavBar.jsx";
import Footer from "../components/Footer.jsx";
import First from "../assets/images/lupa2.png";
import Second from "../assets/images/monedagigante2.png";
import Third from "../assets/images/manos2.png";
import DiagnoseImg from "../assets/images/diagnostico.png";
export default class Develop extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <>
        <header id="headersDesarrollos">
          <Navbar />
          <div className="fixedContainer">

            <h1>
              Desarrollo de
              <span>&nbsp;Negocios</span>
            </h1>
            <h4>Descubre el potencial de tus ideas</h4>
          </div>
        </header>
        <section className="diagnose">
          <div className="fixedContainer">
            <img className="icon" src={First} alt="Diagnose" />
            <h3>Diagnóstico del Negocio</h3>
            <p>
              Desde una perspectiva financiera y estratégica medimos el
              rendimiento actual del Negocio, se identifican áreas de mejora y
              se proponen iniciativas que potencialicen los recursos actuales
              del Negocio.
            </p>
          </div>
          <div className="fixedContainer">
            <img
              className="diagnoseImage"
              src={DiagnoseImg}
              alt="Diagnostico"
            />
          </div>
          <div className="fixedContainer">
            <img className="icon" src={Second} alt="Diagnose" />
            <h3>Desarrollo del Negocio</h3>
            <p>
              Diseñamos estrategias tácticas que maximicen las utilidades del
              negocio desde una perspectiva financiera, fiscal, legal y
              operativa. Nuestros clientes definen el ¿qué?, nosotros los
              apoyamos a desarrollar el ¿cómo?
            </p>
          </div>
          <div className="fixedContainer">
            <img className="icon" src={Third} alt="Diagnose" />
            <h3>
              Asesoría en Compra/Venta Empresas, Alianzas, Proyectos de
              Inversión
            </h3>
            <p>
              Valuación del potencial del Negocio, sinergias o medición de
              cálculo y retorno de inversión. Acompañamiento durante todo el
              proceso de valuación, negociación y elaboración de contratos.
              Apoyo en la materialización de sinergias post-inversión.
            </p>
          </div>
        </section>

        <section className="aboutYou">
          <div className="fixedContainer">
            <h3>¡Queremos saber de ti!</h3>
            <p>
              Ya conoces lo que necesitas? Una llamada o un correo te va a
              acercar más a hacer una realidad los nuevos alcances de tu
              negocio.
            </p>
            <button className="primaryButton"><a href="http://www.bfinancial.com.mx#contacto">Contáctanos</a></button>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
