import React, { Component } from "react";
import Navbar from "../components/NavBar.jsx";
import Footer from "../components/Footer.jsx";
import "../styles/Training.scss";
import Artoboard from "../assets/images/Artboard 2.png";
import Gpotente from "../assets/images/gpogente.png";
import Persona from "../assets/images/graficaaribaypersona.png";
import Tesoreria from "../assets/images/tesoreria.png";
import Contabilidad from "../assets/images/contabilidadpara.png";
import Atumedida from "../assets/images/atumedida.png";
import Potenciar from "../assets/images/potenciaralequipo.png";
import Practica from "../assets/images/practica.png";
import Desarollo from "../assets/images/desarrollode.png";
import Todo from "../assets/images/todo.png"

export default class Training extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <>
        <header id="headerCapacitacion">
          <Navbar />
          <div className="fixedContainer">

            <h1>Capacitación</h1>
            <h4>
              “Desarrollar capacidades financieras y de negocios es nuestra
              meta”
            </h4>
          </div>
        </header>
        <section className="levelUp">
          <div className="fixedContainer">
            <div className="levelUpContainer">
              <article>
                <h2 className="flechita">
                  <img src={Artoboard} alt="Metodologia" />
                  Metodología LevelUp
                </h2>
                <p>
                  Curso especializado en finanzas, desarrollado a la medida del
                  cliente. Eleva en una escala el nivel del conocimiento del
                  equipo de trabajo.
                </p>
              </article>
              <article>
                <h2>
                  <img src={Gpotente} alt="Metodologia" />
                  Curso - Finanzas para todos
                </h2>
                <p>
                  Desarrollo de habilidades financieras en la cadena de valor
                  del negocio. Genera eficiencias en el proceso desde el origen.
                  Desarrollo a la medida del cliente.
                </p>
              </article>
              <article>
                <h2>
                  <img src={Persona} alt="Metodologia" />
                  Curso - Análisis Financiero
                </h2>
                <p>
                  Curso especializado en el entendimiento de los estados
                  financieros de la empresa.
                </p>
              </article>
              <article>
                <h2>
                  <img src={Tesoreria} alt="Metodologia" />
                  Tesorería y Flujos de efectivo
                </h2>
                <p>
                  Entendimiento del flujo de efectivo en la organización y creación del modelo predictivo.
                </p>
              </article>
              <article className="doublePoint">
                <h2>
                  <img src={Contabilidad} alt="Metodologia" />
                  Contabilidad para no contadores
                </h2>
                <p>
                  Curso para toma de decisiones tácticas en la empresa,
                  facilitando una visión financiera del negocio.
                </p>
              </article>
            </div>
          </div>
        </section>
        <section className="metodology">
          <div className="fixedContainer">
            <h2>
              ¿Qué beneficios genera la Metodología LevelUp de BFinancial?
            </h2>
            <div className="metodologyContainer">
              <article>
                <img src={Atumedida} alt="Diseñada" />
                <p>Diseñada a tu medida</p>
              </article>
              <article>
                <img src={Potenciar} alt="Potenciar" />
                <p>
                  Busca Potenciar el equipo actual, en lugar de crecer la
                  estructura de tu Organización
                </p>
              </article>
              <article>
                <img src={Practica} alt="Practica" />
                <p>100% práctica</p>
              </article>
              <article>
                <img src={Desarollo} alt="" />
                <p>Desarrollo de capacidades financieras</p>
              </article>
            </div>
          </div>
        </section>
        <section className="timeline">
          <div className="fixedContainer">
            <div className="timelineContainer">
              {/* <article>
                <img src={Lupa} alt="Diagnóstico" />
                <p>Diagnóstico</p>
              </article>
              <article>
                <img src={Puzzle} alt="Diseño" />
                <p>Diseño del Modelo</p>
              </article>
              <article>
                <img src={Target} alt="Calibración" />
                <p>Calibración</p>
              </article>
              <article>
                <img src={MujerFeliz} alt="Capacitación" />
                <p>Capacitación</p>
              </article> */}
              <article>
                <img src={Todo} alt="Capacitación" />
              </article>
            </div>
          </div>
        </section>
        <section className="aboutYou">
          <div className="fixedContainer">
            <h3>¡Queremos saber de ti!</h3>
            <p>
              Ya conoces lo que necesitas? Una llamada o un correo te va a
              acercar más a hacer una realidad los nuevos alcances de tu
              negocio.
            </p>
            <button className="primaryButton"><a href="http://www.bfinancial.com.mx#contacto">Contáctanos</a></button>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
