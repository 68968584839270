import React, { Component } from "react";
import "../styles/Services.scss";
import Navbar from "../components/NavBar.jsx";
import Footer from "../components/Footer.jsx";
import Primera from "../assets/images/Artboard 3 copy 2@2x.png";
import Segunda from "../assets/images/Artboard 3 copy@2x.png";
import Tercera from "../assets/images/graficaarriba.png";
import Cuarta from "../assets/images/Artboard 3 copy 3@2x.png";
import Quinta from "../assets/images/Artboard 3 copy 4@2x.png";
import Sexta from "../assets/images/calculadoraexperiencia.png";
import Septima from "../assets/images/estructurarobusta.png";
import Octava from "../assets/images/tecnicosespec.png";
import Novena from "../assets/images/menorcosto.png";

export default class Services extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  };

  changeText = (e, action) => {
    e.preventDefault();
    switch (action) {
      case 1:
        document.getElementById("serviciosText").innerHTML = " Con una visión de Contraloría, llevamos a cabo la contabilidad de los Estados Financieros del Negocio alineados a las normas y regulaciones correspondientes. Construimos en conjunto con el cliente, información de calidad útil para la toma de decisiones.";
        document.getElementById("triangulito1").innerHTML = "▾";
        document.getElementById("triangulito2").innerHTML = "";
        document.getElementById("triangulito3").innerHTML = "";
        document.getElementById("triangulito4").innerHTML = "";
        document.getElementById("triangulito5").innerHTML = "";
        break;
      case 2:
        document.getElementById("serviciosText").innerHTML = "Asesoría y gestión técnica fiscal especializada en PYMEs para alinear al negocio al cumplimiento de la normativa aplicable. Ofrecemos el servicio de Consultoría y Defensa fiscal.";
        document.getElementById("triangulito1").innerHTML = "";
        document.getElementById("triangulito2").innerHTML = "▾";
        document.getElementById("triangulito3").innerHTML = "";
        document.getElementById("triangulito4").innerHTML = "";
        document.getElementById("triangulito5").innerHTML = "";
        break;
      case 3:
        document.getElementById("serviciosText").innerHTML = "Pone al alcance de las organizaciones el contar con un Director Financiero y un departamento integral que provea la visión financiera del Negocio, los asesore en la toma de decisiones y guíe a las áreas administrativas con las mejores prácticas para la óptima utilización de los recursos del Negocio.";
        document.getElementById("triangulito1").innerHTML = "";
        document.getElementById("triangulito2").innerHTML = "";
        document.getElementById("triangulito3").innerHTML = "▾";
        document.getElementById("triangulito4").innerHTML = "";
        document.getElementById("triangulito5").innerHTML = "";
        break;
      case 4:
        document.getElementById("serviciosText").innerHTML = "Representación legal laboral ante las Autoridades del Trabajo, como los son las Juntas de Conciliación, Secretaría e Inspección del trabajo, en las relaciones empresa-empleado, consultoría, asesoría, trámites y litigio. <br/> <br/>Asesoría y gestión legal corporativo, elaboración de contratos a proveedores, clientes, convenios de confidencialidad, convenios, contratos de trabajo, acuerdos entre Socios / Accionistas.";
        document.getElementById("triangulito1").innerHTML = "";
        document.getElementById("triangulito2").innerHTML = "";
        document.getElementById("triangulito3").innerHTML = "";
        document.getElementById("triangulito4").innerHTML = "▾";
        document.getElementById("triangulito5").innerHTML = "";
        break;
      case 5:
        document.getElementById("serviciosText").innerHTML = " Estamos comprometidos con la generación de valor a nuestros clientes a través de herramientas que aporten eficiencias, optimización de recursos, incrementen el margen o faciliten la toma de decisiones en el Negocio.";
        document.getElementById("triangulito1").innerHTML = "";
        document.getElementById("triangulito2").innerHTML = "";
        document.getElementById("triangulito3").innerHTML = "";
        document.getElementById("triangulito4").innerHTML = "";
        document.getElementById("triangulito5").innerHTML = "▾";
        break;
      default:
        document.getElementById("serviciosText").innerHTML = "";
        document.getElementById("triangulito1").innerHTML = "";
        document.getElementById("triangulito2").innerHTML = "";
        document.getElementById("triangulito3").innerHTML = "";
        document.getElementById("triangulito4").innerHTML = "";
        document.getElementById("triangulito5").innerHTML = "";
        break;

    }

  }

  render() {
    return (
      <>
        <header id="headersServicios">
          <Navbar />
          <div className="fixedContainer">

            <h1>
              <span>CFO</span> Servicios Financieros Compartidos
            </h1>
            <h4>
              Nuestros Servicios Financieros Compartidos ponen al alcance de tu
              empresa a un Director Financiero y un departamento integral que
              aportan una visión financiera a tu negocio.
            </h4>
          </div>
        </header>
        <section className="director">
          <div className="fixedContainer">
            <p>
              Ponemos a un <span>Director Financiero</span> al alcance de tu
              Organización y un departamento integral que provea una{" "}
              <span>visión Financiera</span> del Negocio, los asesore en la toma
              de decisiones y guíe a los departamentos administrativos con las{" "}
              <span>mejores prácticas y sinergias</span> para la óptima
              utilización de los recursos.
            </p>
          </div>
        </section>
        <section className="levels">
          <div className="fixedContainer">
            <h2>Conoce nuestros niveles de servicio CFO</h2>
            <p className="subtitle"><span>Nuestros servicios financieros compartidos</span> ponen a tu alcance a un <span className="colorsito"> director financiero </span> y un <span className="colorsito">departamento integral </span> para la visión financiera del negocio.</p>
            <div className="levelsExplained">
              <article>
                <h5>N1</h5>
                <h4>Contabilidad Financiera</h4>
                <p>Nivel 1</p>
                <h3>
                  8 Horas de Servicio <br /> 1 Sesión Mensual
                </h3>
                <ul>
                  <li>
                    <span></span> Análisis Financiero
                  </li>
                  <li>
                    <span></span> Políticas Contables
                  </li>
                  <li>
                    <span></span> Planeación del Flujo de Efectivo
                  </li>
                  <li>
                    <span></span> Análisis de Variaciones en los Estados
                    Financieros Mensuales
                  </li>
                  <li>
                    <span></span> Resultados Corporativos Mensuales
                  </li>
                  <li className="unactive">
                    <span></span> Presupuesto y tendencia
                  </li>
                  <li className="unactive">
                    <span></span> Tablero de Indicadores Financieros
                  </li>
                  <li className="unactive">
                    <span></span> Modelos Financieros
                  </li>
                  <li className="unactive">
                    <span></span> Propuesta de eficiencias
                  </li>
                  <li className="unactive">
                    <span></span> Planeación de Capital de trabajo
                  </li>
                  <li className="unactive">
                    <span></span> Análisis de Rentabilidad de Productos Core del
                    Negocio
                  </li>
                  <li className="unactive">
                    <span></span> Administración de Riesgos
                  </li>
                  <li className="unactive">
                    <span></span> Reportes al Consejo de Administración
                  </li>
                  <li className="unactive">
                    <span></span> Planeación de Capital Humano
                  </li>
                  <li className="unactive">
                    <span></span>Relación con Bancos
                  </li>
                </ul>
              </article>
              <article>
                <h5>N2</h5>
                <h4>Finanzas Corporativas</h4>
                <p>Nivel 2</p>
                <h3>
                  16 Horas de Servicio <br /> 2 Sesiones Mensual
                </h3>
                <ul>
                  <li>
                    <span></span> Análisis Financiero
                  </li>
                  <li>
                    <span></span> Políticas Contables
                  </li>
                  <li>
                    <span></span> Planeación del Flujo de Efectivo
                  </li>
                  <li>
                    <span></span> Análisis de Variaciones en los Estados
                    Financieros Mensuales
                  </li>
                  <li>
                    <span></span> Resultados Corporativos Mensuales
                  </li>
                  <li>
                    <span></span> Presupuesto y tendencia
                  </li>
                  <li>
                    <span></span> Tablero de Indicadores Financieros
                  </li>
                  <li>
                    <span></span> Modelos Financieros
                  </li>
                  <li>
                    <span></span> Propuesta de eficiencias
                  </li>
                  <li className="unactive">
                    <span></span> Planeación de Capital de trabajo
                  </li>
                  <li className="unactive">
                    <span></span> Análisis de Rentabilidad de Productos Core del
                    Negocio
                  </li>
                  <li className="unactive">
                    <span></span> Administración de Riesgos
                  </li>
                  <li className="unactive">
                    <span></span> Reportes al Consejo de Administración
                  </li>
                  <li className="unactive">
                    <span></span> Planeación de Capital Humano
                  </li>
                  <li className="unactive">
                    <span></span>Relación con Bancos
                  </li>
                </ul>
              </article>
              <article>
                <h5>N3</h5>
                <h4>CFO Servicios Compartidos</h4>
                <p>Nivel 3</p>
                <h3>
                  32 Horas de Servicio <br /> 4 Sesiones Mensual
                </h3>
                <ul>
                  <li>
                    <span></span> Análisis Financiero
                  </li>
                  <li>
                    <span></span> Políticas Contables
                  </li>
                  <li>
                    <span></span> Planeación del Flujo de Efectivo
                  </li>
                  <li>
                    <span></span> Análisis de Variaciones en los Estados
                    Financieros Mensuales
                  </li>
                  <li>
                    <span></span> Resultados Corporativos Mensuales
                  </li>
                  <li>
                    <span></span> Presupuesto y tendencia
                  </li>
                  <li>
                    <span></span> Tablero de Indicadores Financieros
                  </li>
                  <li>
                    <span></span> Modelos Financieros
                  </li>
                  <li>
                    <span></span> Propuesta de eficiencias
                  </li>
                  <li>
                    <span></span> Planeación de Capital
                  </li>
                  <li>
                    <span></span> Análisis de Rentabilidad de Productos Core del
                    Negocio
                  </li>
                  <li>
                    <span></span> Administración de Riesgos
                  </li>
                  <li>
                    <span></span> Reportes al Consejo de Administración
                  </li>
                  <li>
                    <span></span> Planeación de Capital Humano
                  </li>
                  <li>
                    <span></span>Relación con Bancos
                  </li>
                </ul>
              </article>
            </div>
            <div className="practices">
              <p>
                Asesórate en la toma de decisiones y ten un guía para los
                departamentos administrativos de tu organización con las
                <span> mejores prácticas y sinergias </span> para la óptima
                utilización de los recursos en tu Negocio.
              </p>
              <button className="primaryButton"><a href="http://www.bfinancial.com.mx#contacto">Cotiza tu servicio</a></button>
            </div>
          </div>
        </section>
        <section className="formedBy">
          <div className="fixedContainer">
            <h2>Nuestro servicio esta formado por</h2>
            <div className="formedTiles">
              <article
                onMouseOver={(e) => this.changeText(e, 1)}
                onMouseOut={(e) => this.changeText(e, 1)}>
                <img src={Primera} alt="Contabilidad" />
                <p>Contabilidad</p>
                <p id="triangulito1" className="triangulito"></p>
              </article>
              <article
                onMouseOver={(e) => this.changeText(e, 2)}
                onMouseOut={(e) => this.changeText(e, 2)}>
                <img src={Segunda} alt="Fiscal" />
                <p>Fiscal</p>
                <p id="triangulito2" className="triangulito"></p>
              </article>
              <article
                onMouseOver={(e) => this.changeText(e, 3)}
                onMouseOut={(e) => this.changeText(e, 3)}>
                <img src={Tercera} alt="Finanzas" />
                <p>Finanzas</p>
                <p id="triangulito3" className="triangulito">▾</p>
              </article>
              <article
                onMouseOver={(e) => this.changeText(e, 4)}
                onMouseOut={(e) => this.changeText(e, 4)}>
                <img src={Cuarta} alt="Legal" />
                <p>Legal</p>
                <p id="triangulito4" className="triangulito"></p>
              </article>
              <article
                onMouseOver={(e) => this.changeText(e, 5)}
                onMouseOut={(e) => this.changeText(e, 5)}>
                <img src={Quinta} alt="Procesos" />
                <p>Procesos</p>
                <p id="triangulito5" className="triangulito"></p>
              </article>
            </div>
            <p id="serviciosText">Pone al alcance de las organizaciones el contar con un Director Financiero y un departamento integral que provea la visión financiera del Negocio, los asesore en la toma de decisiones y guíe a las áreas administrativas con las mejores prácticas para la óptima utilización de los recursos del Negocio.</p>
          </div>
        </section>
        <section className="servicesCFO">
          <div className="fixedContainer">
            <h2>
              ¿Cómo <span>los Servicios Financieros Compartidos (CFO) </span>
              pueden traer beneficios a tu organización?
            </h2>
            <div className="servicesTiles">
              <article>
                <img src={Sexta} alt="Experiencia" />
                <p>Experiencia</p>
              </article>
              <article>
                <img src={Septima} alt="Experiencia" />
                <p>Estructura Robusta</p>
              </article>
              <article>
                <img src={Octava} alt="Experiencia" />
                <p>Técnicos Especialistas</p>
              </article>
              <article>
                <img src={Novena} alt="Experiencia" />
                <p>Menor Costo</p>
              </article>
            </div>
          </div>
        </section>
        <section className="aboutYou">
          <div className="fixedContainer">
            <h3>¡Queremos saber de ti!</h3>
            <p>
              Ya conoces lo que necesitas? Una llamada o un correo te va a
              acercar más a hacer una realidad los nuevos alcances de tu
              negocio.
            </p>
            <button className="primaryButton"><a href="http://www.bfinancial.com.mx#contacto">Contáctanos</a></button>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
