import React, { Component } from "react";
import "../styles/Footer.scss";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import LinkedIn from "../assets/images/LinkedIn.png";
export default class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="fixedContainer">
            <img src={Logo} alt="Logo" />
            <p>
              Todos los derechos reservados. BFinancial © 2020 - Cónoce nuestro
              <Link to="/terms">&nbsp;Aviso de privacidad</Link>
            </p>
            <a href="http://linkedin.com/company/bfinancial" target="_blank"  rel="noreferrer"><img src={LinkedIn} alt="Linked" className="linkedin" /></a>
          </div>
        </footer>
      </>
    );
  }
}
