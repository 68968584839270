import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Pages 
import Home from "./pages/Home.jsx";
import Develop from "./pages/Develop.jsx";
import Model from "./pages/Model.jsx";
import Services from "./pages/Services.jsx";
import Training from "./pages/Training.jsx";
import About from "./pages/About.jsx"
import Terms from "./pages/Terms.jsx"

export default class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/desarrollo" element={<Develop />} />
          <Route path="/modelo" element={<Model />} />
          <Route path="/servicios" element={<Services />} />
          <Route path="/capacitacion" element={<Training />} />
          <Route path="/nosotros" element={<About />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
    );
  }
}