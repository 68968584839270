import React, { Component } from "react";
import "../styles/Model.scss";
import Navbar from "../components/NavBar.jsx";
import Footer from "../components/Footer.jsx";
import ModelImg from "../assets/images/modelo.png";

export default class Model extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <>
        <header id="headersFinancieros">
          <Navbar />
          <div className="fixedContainer">

            <h1>
              Modelos
              <span>&nbsp;Financieros</span>
            </h1>
            <h4>Nuestras herramientas están enfocadas en crecimiento</h4>
          </div>
        </header>
        <section className="operation">
          <div className="fixedContainer">
            <div className="operationContainer">
              <article>
                <h2>
                  Diseñamos herramientas de soporte a la operación del Negocio.
                </h2>
                <p>
                  Estamos comprometidos con generar valor a clientes a través de
                  herramientas que aportan
                </p>
                <ul>
                  <li>
                    <span></span> Eficiencia
                  </li>
                  <li>
                    <span></span> Optimización de recursos
                  </li>
                  <li>
                    <span></span> Incrementan el margen
                  </li>
                  <li>
                    <span></span> Facilitan la toma de decisiones en el Negocio.
                  </li>
                </ul>
              </article>
              <article>
                <img src={ModelImg} alt="Modelo" />
              </article>
            </div>
          </div>
        </section>
        <section className="aboutYou">
          <div className="fixedContainer">
            <h3>¡Queremos saber de ti!</h3>
            <p>
              Ya conoces lo que necesitas? Una llamada o un correo te va a
              acercar más a hacer una realidad los nuevos alcances de tu
              negocio.
            </p>
            <button className="primaryButton"><a href="http://www.bfinancial.com.mx#contacto">Contáctanos</a></button>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
