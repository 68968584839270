import React, { Component } from "react";
import Navbar from "../components/NavBar.jsx";
import Footer from "../components/Footer.jsx";

import "../styles/About.scss";
import Ana from "../assets/images/11.png";
import Arturo from "../assets/images/21.png";
import GabyFirst from "../assets/images/31.png";
import Marianela from "../assets/images/41.png";
import AnaSecond from "../assets/images/12.png";
import ArturoSecond from "../assets/images/22.png";
import GabyFirstSecond from "../assets/images/32.png";
import MarianelaSecond from "../assets/images/42.png";


export default class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  };
  
  render() {
    return (
      <>
        <header>
          <Navbar />
          <div className="fixedContainer">

            <h1>
              Conoce a nuestro <span> equipo</span>
            </h1>
            <h4>
              Desarrollar capacidades financieras y de negocio es nuestra meta
            </h4>
          </div>
        </header>
        <section className="team">
          <div className="fixedContainer">
            <div className="teamContainer">
              <article>
                <img src={MarianelaSecond} alt="Marianela Blanco"
                  onMouseOver={e => (e.currentTarget.src = Marianela)}
                  onMouseOut={e => (e.currentTarget.src = MarianelaSecond)}
                />

                <h3>Marianela Blanco</h3>
                <h5>Desarrollo de Negocios Socio Fundador</h5>
                <ul>
                  <li>
                    <span>&#9679;</span>Maestría en Dirección Financiera - Universidad
                    de Barcelona
                  </li>
                  <li>
                    <span>&#9679;</span>Maestría en Administración de Empresas - UDEM
                  </li>
                  <li>
                    <span>&#9679;</span>
                    Ingeniero en Sistemas de Información - ITESM
                  </li>
                  <li>
                    <span>&#9679;</span>
                    15 años experiencia desarrollando empresas en México y
                    LATAM, 12 años de experiencia en el sector financiero.
                  </li>
                </ul>
              </article>
              <article>
                <img src={ArturoSecond} alt="test"
                  onMouseOver={e => (e.currentTarget.src = Arturo)}
                  onMouseOut={e => (e.currentTarget.src = ArturoSecond)}
                />
                <h3>Alfredo Reyes</h3>
                <h5>Asociado Financiero</h5>
                <ul>
                  <li>
                    <span>&#9679;</span>Maestría en impuestos – Centro Universitario Grupo Sol
                  </li>
                  <li>
                    <span>&#9679;</span>Contador Público – Centro Universitario Grupo Sol
                  </li>
                  <li>
                    <span>&#9679;</span>15 años de experiencia en contabilidad, impuestos, auditoria y tesorería
                  </li>
                  <li>
                    <span>&#9679;</span>Experto en control interno. 
                  </li>
                </ul>
              </article>
              <article>
                <img src={AnaSecond} alt="test"
                  onMouseOver={e => (e.currentTarget.src = Ana)}
                  onMouseOut={e => (e.currentTarget.src = AnaSecond)}
                />
                <h3>Ana Perales</h3>
                <h5>Asociado Jurídico</h5>

                <ul>
                  <li>
                    <span>&#9679;</span>Licenciado en Derecho - UANL
                  </li>
                  <li>
                    <span>&#9679;</span>Especialista en materia laboral.
                  </li>
                  <li>
                    <span>&#9679;</span>+15 años de experiencia asesorando y
                    capacitando empresas en materia laboral y apego a regulación
                    y normas.
                  </li>
                </ul>
              </article>
              <article id="columnaSordeada">
                <img src={GabyFirstSecond} alt="test"
                  onMouseOver={e => (e.currentTarget.src = GabyFirst)}
                  onMouseOut={e => (e.currentTarget.src = GabyFirstSecond)}
                />
                <h3>Gaby Benavides</h3>
                <h5>Asociado Proyectos</h5>

                <ul>
                  <li>
                    <span>&#9679;	</span>
                    LASCA - ITESM
                  </li>
                  <li>
                    <span>&#9679;	</span>Estudios en metodologías para la administración
                    de proyectos y procesos.
                  </li>
                  <li>
                    <span>&#9679;	</span>+50 proyectos implementados en eficiencia,
                    captación de Negocio y control de riesgos.
                  </li>
                  <li>
                    <span>&#9679;</span>+15 años de experiencia en el sector financiero
                    en México.
                  </li>
                </ul>
              </article>
            </div>
          </div>
        </section>
        <section className="typeTiles">
          <div className="imageLeft">
            <article className="fitthTile"></article>
            <article>
              <h2>Nuestro enfoque</h2>
              <p>
                Somos un equipo de consultores financieros, centrados en el
                desarrollo de personas para transformar equipos tácticos en
                catalizadores de oportunidades de negocio dentro de su
                organización.
              </p>
            </article>
          </div>
          <div className="imageRight">
            <article>
              <h2>Historia</h2>
              <p>
                Contamos con más de 20 años de experiencia en el sector
                financiero en México, con proyectos implementados en México y
                LATAM.
              </p>
            </article>
            <article className="sixthTile"></article>
          </div>
          <div className="imageLeft">
            <article className="seventhTile"></article>
            <article>
              <h2>Experiencias</h2>
              <ul>
                <li>Banca y Sector Financiero</li>
                <li>Sector Automotriz</li>
                <li>Metalmecánica</li>
                <li>Sector de Construcción</li>
                <li>Industria de Alimentos</li>
                <li>Tecnologías de Información</li>
              </ul>
            </article>
          </div>
        </section>
        <section className="aboutYou">
          <div className="fixedContainer">
            <h3>¡Queremos saber de ti!</h3>
            <p>
              Ya conoces lo que necesitas? Una llamada o un correo te va a
              acercar más a hacer una realidad los nuevos alcances de tu
              negocio.
            </p>
            <button className="primaryButton"><a href="http://www.bfinancial.com.mx#contacto">Contáctanos</a></button>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
