import React, { Component } from "react";
import "../styles/NavBar.scss";
import { Link } from "react-router-dom";

import Logo from "../assets/images/logo.png";
import LinkedIn from "../assets/images/LinkedIn.png";
import Menu from "../assets/images/menu.png";
// import { Jump } from "react-jump";
import Close from "../assets/images/Close.png";

export default class NavBar extends Component {
  showMenu = () => {
    let menu = document.getElementById("dropdown");
    if (menu.style.display === "none" || menu.style.display === "") {
      menu.style.display = "block";
    } else {
      menu.style.display = "none";
    }
  };
  showMenuMobile = () => {
    let menuMobile = document.getElementById("mobile");
    if (
      menuMobile.style.display === "none" ||
      menuMobile.style.display === ""
    ) {
      menuMobile.style.display = "block";
    } else {
      menuMobile.style.display = "none";
    }
  };
  render() {
    return (
      <>
        <nav>
          <div className="contenedorNav">
            <Link to="/">
              <img className="logo" src={Logo} alt="Logo" />
            </Link>
            <ul>
              <li>
                <Link to="/nosotros">Nosotros</Link>
              </li>
              <li>
                <button
                  onMouseEnter={(e) => this.showMenu()}
                // onMouseLeave={(e) => this.showMenu()}
                >
                  Soluciones
              </button>
                <article id="dropdown" onMouseLeave={(e) => this.showMenu()}>
                  <span>
                    <Link to="/desarrollo">Desarrollo de negocios</Link>
                  </span>
                  <span>
                    <Link to="/modelo">Modelos Financieros</Link>
                  </span>
                  <span>
                    <Link to="/servicios">CFO Servicios Financieros</Link>
                  </span>
                  <span>
                    <Link to="/capacitacion">Capacitación</Link>
                  </span>
                </article>
              </li>
              {/* <li><a href="http://www.bfinancial.com.mx/blog">Blog </a></li> */}
              <li>
                <a href="http://www.bfinancial.com.mx#contacto">Contacto</a>
              </li>
              <li>
                <img src={LinkedIn} alt="" />
              </li>
            </ul>
            <button onClick={(e) => this.showMenuMobile()} className="menuButton">
              <img src={Menu} alt="Menu" />
            </button>
          </div>
        </nav>
        <div id="mobile" className="mobileMenu">
          <button onClick={(e) => this.showMenuMobile()}>
            <img src={Close} alt="Close" />
          </button>
          <ul>
            <li>
              <Link to="/nosotros">Nosotros</Link>
            </li>
            <li>
              <Link to="/desarrollo">Desarrollo de negocios</Link>
            </li>
            <li>
              <Link to="/modelo">Modelos Financieros</Link>
            </li>
            <li>
              <Link to="/servicios">CFO Servicios Financieros</Link>
            </li>
            <li>
              <Link to="/capacitacion">Capacitación</Link>
            </li>
            <li>
              <Link to="/#contacto">Contacto</Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
